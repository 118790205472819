<template>
  <div>
    <p class="text-h6">Meine Prüfung</p>
    <p class="text-body-2">In der Praktischen Fahrerlaubnisprüfung wird festgestellt, wie gut Sie die geforderten
      Fahraufgaben bewältigen können.
      Hier können Sie sich Beispiele für die richtige Ausführung der Fahraufgaben ansehen.<br>
      Bitte beachten Sie, dass es sich bei den Kurzfilmen mit erklärenden Erläuterungen (Ton) um eine beispielhafte
      Darstellung handelt.
    </p>
    <v-divider></v-divider>
    <Playlist v-show="getPlaylistItems.length >0"
              title="Schwerer Fehler"
              bannerBottomColor="red"
              :Playlist=getPlaylistItems></Playlist>
    <Playlist v-show="getPlaylistMinorItems.length >0"
              title="Leichter Fehler"
              bannerBottomColor="#ffca28"
              :Playlist=getPlaylistMinorItems></Playlist>
  </div>

</template>

<script>
import Vue from "vue";
import Playlist from "@/components/panels/Playlist";

export default Vue.extend({
  name: "Exam",
  components: {Playlist},
  data: () => ({
    model: [],
    item: {
      drivinglicence: String,
      dt: Number,
      sdt: Number,
      sitsub: Number,
      obscat: Number,
      perfcat: Number,
      desc: String,
    },

  }),
  computed: {
    getPlaylistItems() {
      return this.$store.state.playlistItems.filter(item => {
        return item.data.perfcat === 3
      })
    },
    getPlaylistMinorItems() {
      return this.$store.state.playlistItems.filter(item => {
        return item.data.perfcat === 2
      })
    },

  },
  created() {
    this.fetchJSONData();
  },
  methods: {
    fetchJSONData: function () {
      const playlist = this.$store.state.playlistIds;
      const playlistitems = [];
      const sitsubs = ["a", "b", "c", "d", "e", "f"];
      const subpath = Number(this.$store.state.eppversion) === Number(2) ? "v2/" : "";
      for (let argumentsKey in playlist) {
        if (!parseInt(playlist[argumentsKey])) continue;
        let id = parseInt(playlist[argumentsKey]);
        if (Number.isInteger(id)) {
          if (id < 0 && id > 15000) {//if id is not in range between 0-15000 continue
            continue
          }
        } else {
          continue
        }
        let path = this.getJSONPathOfID(String(id));
        fetch("/json/" + subpath + path)
          .then(this.checkError)
          .then(data => {
            const VideoPanelP = {
              id: Number,
              data: {},
              title: '',
              src: '',
              video_poster: '',
              description: '',
              showPanel: false,
              timeValue: 0
            }
            data.sitsub = sitsubs.indexOf(playlist[parseInt(argumentsKey) + 1]);
            VideoPanelP.data = data;
            VideoPanelP.id = id;
            this.getTranscriptFromVideoP(VideoPanelP);
            playlistitems.push(VideoPanelP);
          }).catch((error) => {
          console.log(error)
        });
      }
      playlistitems.sort((a, b) => {
        return a.data.dt - b.data.dt;
      });
      this.$store.commit('ADD_PLAYLISTITEMS', playlistitems);
    },
    checkError: function (response) {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    },
    getJSONPathOfID(id) {
      let path = "";
      if (id.length === 1) {
        path = id + "/" + id + ".json"
      } else {
        path = id[0] + "/" + id[1] + "/" + id + ".json"
      }
      return path;
    },
    getVideoName(item) {
      let sitsubarray = ["I", "II", "III", "IV"]
      let dt = item.data.dt;
      let sdt = item.data.sdt === 0 ? 1 : item.data.sdt;
      let obscat = item.data.obscat;
      let sitsub = item.data.sitsub === 0 ? "" : "-" + sitsubarray[item.data.sitsub - 1];
      let name = dt + "." + sdt + "." + 2 + "." + obscat + "" + sitsub /*+ "-" + drivclass*/
      return this.getVideoNameForDrivingClass(name, item);
    },
    getTranscriptFromVideoP(item) {
      item.src = this.getVideoName(item) + '.mp4';
      let path = this.getVideoName(item) + ".json";
      fetch("/json/transcript/" + path)
        .then(this.checkError)
        .then(data => {
          item.description = data.transcript;
        }).catch((error) => {
        console.log(error)
      });
    },
    getVideoNameForDrivingClass(name, item) {
      const json_fe = this.$store.state.fe_video_map;
      let vname = '';
      for (let key in json_fe) {
        if (key.includes(name)) {
          vname = name;
          if (json_fe[key].split(',').includes(item.data.drivinglicence)) {
            return key.toString();
          }
        }
      }
      return vname;
    }
  }

})
;
</script>

<style scoped>

</style>